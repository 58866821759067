<template>
  <div class="page">
    <div class="page-title">
      <img @click="$router.push('/')" src="@/assets/images/back.svg">
      Часто задаваемые вопросы
    </div>

    <ECAccordion
      v-for="q in faq"
      :key="q.id"
      :title="q.attributes.question"
    >
      <div class="accordion-inner content-strapi" v-html="q.attributes.answer" />
    </ECAccordion>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import ECAccordion from '@/components/ui/ECAccordion.vue'

const uploadsEnv = process.env.VUE_APP_STRAPI_UPLOADS

onMounted(async()=> await getFAQ())

const faq = ref(null)

const getFAQ = async () => {
  const req = await axios.get(`${process.env.VUE_APP_STRAPI_API}/faqs`)
  faq.value = req.data.data
}
</script>

<style scoped lang="scss">
  .page-title{
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    padding-left: 0;
    img{
      margin-right: 10px;
      margin-top: 3px;
      cursor: pointer;
    }
  }
</style>

