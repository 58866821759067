<template>
  <div
    class="accordion"
    ref="target"
  >
    <div
      class="accordion__title"
      @click="showInner = !showInner"
    >
      <h4>{{ title }}</h4>
      <img
        src="@/assets/images/arrow-accord.svg"
        :style="`transform: rotate(${showInner ? '180deg' : '0'})`"
      >
    </div>
    <transition name="default">
      <div class="accordion__group" v-if="showInner">
        <slot/>

      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  title: {
    type: String,
    required: true
  }
})

const target = ref(null)
const showInner = ref(false)

onClickOutside(target, (event) => showInner.value = false)
</script>

<style scoped lang="scss">
.accordion{
  background: white;
  border-radius: 16px;
  margin-bottom: 16px;
  &__title{
    display: flex;
    padding: 17px 20px;
    align-items: center;
    cursor: pointer;
    h4{
      flex: 1;
      font-size: 18px;
      line-height: 120%;
    }
    img{
      margin-right: 16px;
      transition: ease .2s;
      &:last-child{
        margin: 0;
        cursor: pointer;
      }
    }
  }
  &__group{
    padding: 10px 20px 10px 20px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: 17px;
      right: 17px;
      top: 0;
      height: 2px;
      border-radius: 13px;
      background: #D9D9D9;
    }
  }
}
</style>